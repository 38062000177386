import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RootStore } from '../../stores/root.store';
import {
    AddReportWizard,
    AddReportWizardSteps,
    AddReportWizardResult,
} from '../../components/add-report-wizard';
import { WidgetDataValues, ReportModuleGroup } from '../../models/report';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { Marker } from '../../models/marker';
import { Typography, Button, Divider } from '@mui/material';
import { ccLogoBottom } from '../../shared/assets/images/cc-logo';
import { ReportsTabUtilities } from './reports-tabs-utilities';
import AppStoreDownloadBadgeUS from '../../shared/components/icons/app-store-download-badge-US';
import { MOBILE } from '../../constants';
import { GOOGLE_PLAY_BADGE } from '../../shared/assets/images/google-play-badge';

const styles = (theme: Theme) =>
    createStyles({
        spinner: {},
        addReportWizard: {
            minWidth: '41em',
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                minWidth: 'auto',
            },
        },
        ccLogo: {
            height: '11em',
            marginTop: '2em',
        },
        invalidMarkerContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
        },
        title: {
            flex: '1',
            marginTop: '6%',
            padding: '1em',
            textAlign: 'center',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '2.5em',
                wordBreak: 'break-word',
            },
        },
        subtitleContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 4,
            alignItems: 'center',
            margin: '0 2em',
        },
        subtitleText: {
            textAlign: 'center',
        },
        downloadAppButtonsContainer: {
            margin: '0.5em 0 4em 0',
        },
        appStoreBadge: {
            margin: '0.6em',
        },
        googlePlayBadge: {
            width: '10.4em',
        },
        buttonLogout: {
            width: '9em',
            margin: '2em auto',
        },
        divider: {
            margin: '1.6em 0 1.55em 0',
        },
        homeLink: {
            color: theme.palette.secondary.main,
            fontSize: '1.2em',
            marginTop: '1em'
        },
    });

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
}

interface ForbiddenQRScanningDataDescriptorItem {
    header_text: string;
    link: string;
}
interface ForbiddenQRScanningData {
    descriptor: {
        android: ForbiddenQRScanningDataDescriptorItem;
        apple: ForbiddenQRScanningDataDescriptorItem;
        header_text: string;
    };
    message: string;
}

interface States {
    currentMarker: Marker | undefined;
    currentModuleGroup: ReportModuleGroup | undefined;
    currentStep: AddReportWizardSteps;
    errorText: string;
    isLoading: boolean;
    showThankYouPage: boolean;
    cancelled: boolean;
    forbiddenQRScanningData: ForbiddenQRScanningData | undefined;
}

const DEFAULT_STATES: States = {
    currentMarker: undefined,
    currentModuleGroup: undefined,
    currentStep: AddReportWizardSteps.SubmitReport,
    errorText: '',
    isLoading: true,
    showThankYouPage: false,
    cancelled: false,
    forbiddenQRScanningData: undefined,
};

@inject('rootStore')
@observer
class QRScan extends React.Component<Props, States> {
    // DO NOT REMOVE Partial<Props>. It is needed by the rootStore
    public static defaultProps: Partial<Props> = {};

    reportsTabUtilities: ReportsTabUtilities | undefined = undefined;
    timeoutId: NodeJS.Timeout | undefined = undefined;

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = { ...DEFAULT_STATES };
        const {
            rootStore: { managerStore },
        } = this.props;

        // Retrieve marker id from store
        const qrMarkerId = managerStore.qrMarkerId;

        this.reportsTabUtilities = new ReportsTabUtilities(managerStore);

        managerStore
            .getMarkerById(qrMarkerId)
            .then((currentMarker?: Marker) => {
                if (currentMarker) {
                    managerStore
                        .getModulesByMarker(currentMarker?.id)
                        .then((moduleGroups: ReportModuleGroup[]) => {
                            if (!moduleGroups || moduleGroups.length < 1) {
                                this.setState({
                                    errorText: `Unable to open a new report for '${currentMarker?.name}' with your credentials.`,
                                    isLoading: false,
                                });
                            }
                            this.setState({
                                currentMarker,
                                isLoading: false,
                            });
                        })
                        .catch(() => {
                            this.setState({
                                errorText: `Unable to load modules for marker '${currentMarker?.name}'`,
                                isLoading: false,
                            });
                        });
                } else {
                    this.setState({
                        errorText: `Unable to load the marker with ID '${qrMarkerId}'`,
                        isLoading: false,
                    });
                }
            })
            .catch(
                (response: {
                    error: string;
                    status?: number;
                    data: ForbiddenQRScanningData;
                }) => {
                    if (managerStore.isSessionExpired) {
                        managerStore.setQRMarkerId(qrMarkerId);
                        managerStore.logout();
                        return;
                    }

                    if (typeof response === 'string') {
                        this.setState({
                            errorText: response,
                            isLoading: false,
                        });
                    } else {
                        const { status, data: forbiddenQRScanningData } = response;

                        if (status === 403) {
                            this.setState({
                                forbiddenQRScanningData,
                                isLoading: false,
                            });
                        } else {
                            this.setState({
                                errorText: 'Invalid Marker ID',
                                isLoading: false,
                            });
                        }
                    }
                }
            ).finally(() => {
                // Clear the marker id
                managerStore.setQRMarkerId('');
            });
    }

    onLogout = () => {
        const {
            rootStore: { managerStore },
        } = this.props;
        managerStore.logout();
    };

    goToThankYouScreen = () => {
        this.setState({
            errorText: '',
            showThankYouPage: true,
        }, () => {
            // Scroll to top after open the page screen
            document.getElementById('root')?.scrollIntoView();
        });
    };

    submitReport = (widgetDataValues: WidgetDataValues): Promise<void> => {
        if (!this.reportsTabUtilities) {
            return Promise.reject();
        }

        const { currentMarker, currentModuleGroup } = this.state;

        return this.reportsTabUtilities.submitReport(
            widgetDataValues,
            currentMarker,
            currentModuleGroup
        );
    };

    onReportWizardModuleGroupSelected = (
        currentModuleGroup: ReportModuleGroup
    ) => {
        this.setState({ currentModuleGroup });
        const { currentMarker } = this.state;
        const {
            rootStore: { managerStore },
        } = this.props;

        const markerId: string = currentMarker ? currentMarker.id : '';
        const moduleGroupId: string = currentModuleGroup
            ? currentModuleGroup.id
            : '';

        managerStore.getModuleInfo(markerId, moduleGroupId).catch(() => {
            this.setState({
                errorText: `Unable to load modules info '${currentModuleGroup?.name}' for marker '${currentMarker?.name}'`,
            });
        });
    };

    onReportWizardResult = (
        result: AddReportWizardResult,
        widgetValues?: WidgetDataValues
    ) => {
        switch (result) {
            case AddReportWizardResult.Cancel: {
                this.goToThankYouScreen();
                this.setState({
                    cancelled: true,
                    errorText: 'Request Cancelled',
                });
                break;
            }

            case AddReportWizardResult.Back: {
                const { currentStep } = this.state;

                let nextStep: AddReportWizardSteps;
                if (currentStep === AddReportWizardSteps.SubmitReport) {
                    nextStep = AddReportWizardSteps.QRLocation;
                } else {
                    nextStep = currentStep;
                }

                // Reset the state for step #2
                let newState = { currentStep: nextStep };
                if (nextStep === AddReportWizardSteps.QRLocation) {
                    newState = Object.assign(newState, {
                        addReportModuleGroup: '',
                    });
                }

                this.setState(newState);
                break;
            }

            case AddReportWizardResult.Submit: {
                this.setState({ isLoading: true });
                if (widgetValues) {
                    this.submitReport(widgetValues)
                        .then(() => {
                            this.setState({
                                ...DEFAULT_STATES,
                                ...{ isLoading: false },
                            });
                            this.goToThankYouScreen();
                        })
                        .catch((error) => {
                            this.setState({
                                errorText: error,
                                isLoading: false,
                            });
                        });
                }
            }
        }
    };

    public render() {
        const {
            classes,
            rootStore: { managerStore },
        } = this.props;
        const {
            currentMarker,
            currentModuleGroup,
            currentStep,
            errorText,
            isLoading,
            showThankYouPage,
            cancelled,
            forbiddenQRScanningData,
        } = this.state;

        const { reportModuleGroups, reportModules, isReportModulesLoading } =
            managerStore;

        return (
            <CCSpinner
                className={classes.spinner}
                blockPageScroll={false}
                loading={isLoading}
                overlayVisible={true}
                size={100}
            >
                {!errorText && !forbiddenQRScanningData && !showThankYouPage ? (
                    <AddReportWizard
                        className={classes.addReportWizard}
                        open={true}
                        isLoading={isReportModulesLoading}
                        steps={[
                            AddReportWizardSteps.QRLocation,
                            AddReportWizardSteps.SubmitReport,
                        ]}
                        isBackButtonVisible={
                            currentStep !== AddReportWizardSteps.SubmitReport
                        }
                        currentStep={currentStep}
                        selectedSearchItem={currentMarker}
                        reportModules={reportModules}
                        reportModuleGroups={reportModuleGroups}
                        searchRowsPerPage={8}
                        selectedReportModuleGroupId={
                            currentModuleGroup ? currentModuleGroup.id : ''
                        }
                        showStepper={false}
                        onWizardResult={this.onReportWizardResult}
                        onReportModuleGroupSelect={
                            this.onReportWizardModuleGroupSelected
                        }
                    />
                ) : (
                    <div className={classes.invalidMarkerContainer}>
                        <img
                            className={classes.ccLogo}
                            alt="CrowdComfort Logo"
                            src={ccLogoBottom}
                        />
                        {forbiddenQRScanningData && !forbiddenQRScanningData.message ? (
                            ''
                        ) : (
                            <Typography className={classes.title} variant="h2">
                                {forbiddenQRScanningData
                                    ? forbiddenQRScanningData.message
                                    : errorText
                                        ? errorText
                                        : 'Thank you for submitting your request'
                                }
                            </Typography>
                        )}
                        <div className={classes.subtitleContainer}>
                            <Typography
                                className={classes.subtitleText}
                                variant="h5"
                            >
                                Follow status updates here:
                            </Typography>
                            <a
                                href="/"
                                className={classes.homeLink}
                            >
                                https://dashboard.crowdcomfort.com/
                            </a>
                            <Divider className={classes.divider}/>
                            {forbiddenQRScanningData && !forbiddenQRScanningData.descriptor.header_text ? (
                                ''
                            ) : (
                                <Typography
                                    className={classes.subtitleText}
                                    variant="h5"
                                >
                                    {forbiddenQRScanningData
                                        ? forbiddenQRScanningData.descriptor.header_text
                                        : 'Download our mobile app to receive status updates on your request and enjoy an enhanced CrowdComfort experience.'}
                                </Typography>
                            )}
                            <div
                                className={classes.downloadAppButtonsContainer}
                            >
                                {forbiddenQRScanningData && !forbiddenQRScanningData.descriptor.apple?.link ? (
                                    ''
                                ) : (
                                    <a
                                        href={
                                            forbiddenQRScanningData
                                                ? forbiddenQRScanningData.descriptor.apple.link
                                                : MOBILE.IOS.STORE_LINK
                                        }
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <AppStoreDownloadBadgeUS
                                            className={classes.appStoreBadge}
                                        />
                                    </a>
                                )}
                                {forbiddenQRScanningData && !forbiddenQRScanningData.descriptor.android?.link ? (
                                    ''
                                ) : (
                                    <a
                                        href={
                                            forbiddenQRScanningData
                                                ? forbiddenQRScanningData.descriptor.android.link
                                                : MOBILE.ANDROID.STORE_LINK
                                        }
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <img
                                            className={classes.googlePlayBadge}
                                            alt="Google Play Badge"
                                            src={GOOGLE_PLAY_BADGE}
                                        />
                                    </a>
                                )}
                            </div>
                            <Typography
                                className={classes.subtitleText}
                                variant="h5"
                            >
                                {cancelled
                                    ? 'To return to the sign in page, please click below.'
                                    : 'If you have finished submitting your reports, please log out by clicking the button below.'}
                            </Typography>
                            <Button
                                className={classes.buttonLogout}
                                variant="contained"
                                color="primary"
                                onClick={this.onLogout}
                            >
                                Log out
                            </Button>
                        </div>
                    </div>
                )}
            </CCSpinner>
        );
    }
}

const MUIComponent = withStyles(styles)(QRScan);
export { MUIComponent as QRScan };
