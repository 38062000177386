import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import {
    Checkbox,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Typography,
    Button,
    ListItemSecondaryAction,
    IconButton
} from '@mui/material';
import { SimpleModal } from '../../shared/components/simple-modal';
import { SearchResultsUser } from '../../adapters/report-base-adapter';
import { SearchInput } from '../../shared/components/search-input';
import { EntityPCN, PCNAssignmentUser } from '../../shared/domain';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { CCSingleDatePicker } from '../cc-single-date-picker';
import { CCSpinner } from '../../shared/components/cc-spinner';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogResult, SimpleDialog } from '../../shared/components/simple-dialog';

dayjs.extend(timezone);
dayjs.extend(utc);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        borderRadius: '0.4em',
        maxHeight: '92%',
        minWidth: '36em',
        width: '70%',
        maxWidth: '80em',
        overflow: 'auto',
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone12ProMax)]: {
            minWidth: 'auto',
            minHeight: 'auto',
            borderRadius: 0
        }
    },
    modalContent: {
        width: '100%',
        height: '100%',
        padding: '0 0.5em'
    },
    title: {
        marginTop: '1em',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '0.2em',
        borderBottom: '1px solid'
    },
    filter: {
        width: '100%',
        padding: '1em'
    },
    footer:{
        alignItems: 'center',
        flex: 1,
        padding: '1em',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8)]: {
            padding: '1em 0.5em'
        }
    },
    newUserContainer: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    userInput : {
        flex: '1',
        marginRight: '2em'
    },
    userListContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '96%'
    },
    userList: {
        flex: '1',
        marginBottom: '1em',
        overflow: 'auto'
    },
    pclUserList: {
        overflow: 'auto',
        maxHeight: '15em',
        marginBottom: '1em'
    },
    datePicker: {
        margin: '1em'
    },
    spinner: {
        zIndex: 1,
        position: 'fixed',
    }
});

interface Props {
    className?: string;
    selectedFolder: string;
    users: PCNAssignmentUser[];
    listUsers: SearchResultsUser[];
    selectedPcn: EntityPCN;
    open: boolean;
    isLoading: boolean;
    onCancelClick: () => void;
    onFilterUserByEmail: (email: string) => void;
    onDeleteUserAssignment: (secondaryId: string) => void;
    onSubmit: (selectedUsers: SearchResultsUser[], startDate: Date, endDate?: Date | undefined) => void;
}

interface States {
    selectedUsers: SearchResultsUser[];
    prevSelectedUsers: SearchResultsUser[];
    filteredEmail: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
    dialogOpen: boolean;
    dialogMessage: string;
}

class AssignRouteWizard extends React.Component<Props & WithStyles<typeof styles>, States> {
    public static defaultProps = {
        isLoading: false
    };
    // Get current date
    currentDate = dayjs() as unknown as Date | undefined;

    state = {
        selectedUsers: [] as SearchResultsUser[],
        prevSelectedUsers: [] as SearchResultsUser[],
        filteredEmail: '',
        startDate: this.currentDate,
        endDate: undefined as Date | undefined,
        dialogOpen: false,
        dialogMessage: ''
    };

    resetModal = () => {
        this.setState({
            selectedUsers: [],
            prevSelectedUsers: [],
            filteredEmail: '',
            startDate: this.currentDate,
            endDate: undefined as Date | undefined
        });
    };

    onCancelModal = () => {
        const { onCancelClick } = this.props;

        onCancelClick();
        this.resetModal();
    };

    handleToggle = (user: SearchResultsUser) => () => {
        this.setState({selectedUsers: [user]});
    };

    onSubmit = () => {
        const { selectedUsers, startDate, endDate } = this.state;
        const { onSubmit } = this.props;
        
        if (startDate) {
            onSubmit(selectedUsers, startDate, endDate);
        }

        this.resetModal();
    };

    filterUserByEmail = (email: string) => {
        const { onFilterUserByEmail } = this.props;

        onFilterUserByEmail(email);
        this.setState({filteredEmail: email.toLowerCase()});
    };

    onStartDateChange = (date: Date) => {
        this.setState({ startDate: date });
    };

    onEndDateChange = (date: Date) => {
        this.setState({ endDate: date });
    };

    onFilterChanged = (value: string) => {
        this.setState({filteredEmail: value});
    };

    onDeleteItemClicked = (user: PCNAssignmentUser) => {
        const { email } = user;
        this.setState({
            dialogOpen: true,
            dialogMessage: `Are you sure to remove the assignment for ${email}?`
        });
    };

    onDialogResult = (result: DialogResult) => {
        this.setState({
            dialogOpen: false,
            dialogMessage: ''
        });

        if (result > 1) {
            return;
        }

        const {
            selectedPcn: { pcnId },
            onDeleteUserAssignment
        } = this.props;

        onDeleteUserAssignment(pcnId);
    };

    public render() {
        const {
            classes,
            className,
            open,
            selectedFolder,
            users,
            listUsers,
            selectedPcn: {
                pcnValue
            },
            isLoading
        } = this.props;

        const {
            selectedUsers,
            startDate,
            endDate,
            filteredEmail,
            dialogOpen,
            dialogMessage
        } = this.state;
        const isAssignUsersDisabled = selectedUsers.length && startDate ? false : true;

        return (
            <>
                <SimpleModal
                    className={`${classes.root}${className ? ` ${className}` : ''}`}
                    open={open}
                    buttonOkLabel=""
                    onModalResult=""
                    footer={
                        <div className={classes.footer}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.onCancelModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.onSubmit}
                                disabled={isAssignUsersDisabled}
                            >
                                Continue
                            </Button>
                        </div>
                    }
                >
                    <div className={classes.modalContent}>
                        <Typography
                            className={classes.title}
                            variant="h5"
                        >
                            { !users.length ?
                                `Assign a new user to: ${selectedFolder} - ${pcnValue}`
                                : `User assigned to: ${selectedFolder} - ${pcnValue}` }
                        </Typography>
                        <div className={classes.userListContainer}>
                            { !users.length ? ''
                                : <List className={classes.userList}>
                                    { users.map((user: PCNAssignmentUser) => {
                                        const { user_id, email, end_time, start_time } = user;
                                        const startDateFormated = dayjs(start_time).format('ll');
                                        const endDateFormated = end_time ? dayjs(end_time).format('ll') : undefined;
                                        return (
                                            <ListItem key={user_id}>
                                                <ListItemText primary={email} secondary={
                                                    <>
                                                        From {startDateFormated}{endDateFormated ? ` to ${endDateFormated}` : ''}
                                                    </>
                                                }/>
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        aria-label="Delete"
                                                        onClick={() => this.onDeleteItemClicked(user)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            }
                            <div>
                                { !users.length ? ''
                                    : <Typography
                                        className={classes.title}
                                        variant="h5"
                                    >
                                        Change or assign new user
                                    </Typography>
                                }
                                <SearchInput
                                    className={classes.filter}
                                    placeholder="Type user's email"
                                    buttonText="Search"
                                    disabled={filteredEmail.length < 3} // Enable since 3 characters typed
                                    onSearchClick={this.filterUserByEmail}
                                    onSearchChange={this.onFilterChanged}
                                />
                                <CCSingleDatePicker
                                    className={classes.datePicker}
                                    defaultValue={this.currentDate} 
                                    minDate={this.currentDate}
                                    maxDate={endDate}
                                    label="Start Date"
                                    onDateChange={this.onStartDateChange}
                                />
                                <CCSingleDatePicker
                                    className={classes.datePicker}
                                    disabled={!startDate}
                                    minDate={startDate}
                                    label="End Date"
                                    onDateChange={this.onEndDateChange}
                                />
                                <List className={!users.length ? classes.userList : classes.pclUserList}>
                                    { 
                                        listUsers.map((user: SearchResultsUser) => {
                                            const { user_id, email } = user;
                                            return (
                                                <ListItem key={user_id}>
                                                    <ListItemButton role={undefined} onClick={this.handleToggle(user)} dense>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                edge="start"
                                                                checked={!(selectedUsers.filter(selectedUser => selectedUser.user_id === user.user_id).length) ? false : true}
                                                                tabIndex={-1}
                                                                disableRipple
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText primary={email} />
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })
                                    }
                                </List>
                            </div>
                        </div>
                        <CCSpinner
                            className={classes.spinner}
                            loading={isLoading}
                            overlayVisible={true}
                            size={100}
                        />
                    </div>
                </SimpleModal>
                <SimpleDialog
                    open={dialogOpen}
                    titleText="Remove Assignment"
                    contentText={dialogMessage}
                    onDialogResult={this.onDialogResult}
                    disableTransition={true}
                />
            </>
        );
    }
}

const MUIComponent = withStyles(styles)(AssignRouteWizard);
export {MUIComponent as AssignRouteWizard};
