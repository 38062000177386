/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

// axios.interceptors.response.use((response) => {
//     return response;
//   }, (error) => {
//     return Promise.reject({ error });
//   });

export class HttpClient {

    static Utilities = {
        stringifyGetParams: (params: any): string => {
            const keys = Object.keys(params);
            let options = '';
        
            keys.forEach((key) => {
                const isParamTypeObject = typeof params[key] === 'object';
                const isParamTypeArray = isParamTypeObject && (Array.isArray(params[key]));

                // Check if type is not Object and value is not empty or has 'enter' as character
                if (!isParamTypeObject && (params[key] !== '\n' || params[key] !== '')) {
                    options += `${key}=${encodeURIComponent(params[key])}&`;
                }
            
                if (isParamTypeObject && isParamTypeArray) {
                    (params[key] as string[]).forEach((element: string) => {
                        options += `${key}=${encodeURIComponent(element)}&`;
                    });
                }
            });
        
            return options ? options.slice(0, -1) : options;
        }
    };

    static get(url: string, params?: unknown, headers?: unknown, isBinary?: boolean): Promise<AxiosResponse> {
        return new Promise ((resolve, reject) => { 
            const axiosParams = params ? {
                params
            } : undefined;
            const axiosHeaders = headers ? {
                headers
            } : undefined;
            let axiosConfig: AxiosRequestConfig | undefined =
                (axiosParams || axiosHeaders) ?
                    Object.assign({}, axiosParams, axiosHeaders) : undefined;
            
            if (isBinary === true) {
                axiosConfig = Object.assign({}, axiosConfig, {responseType: 'arraybuffer'});
            }

            axios
                .get(url, axiosConfig)
                .then(response => {
                    const {
                        data,
                        headers: responseHeaders
                    } = response;
                    if ((typeof data) === 'string') {
                        const { 'content-type': contentTypeRaw }: { 'content-type': string } = responseHeaders;
                        const contentType = contentTypeRaw ? contentTypeRaw.toLowerCase() : '';
                        if (contentType.indexOf('text/html') >= 0) {
                            try {
                                response.data = JSON.parse(data);
                            } catch(error) {
                                return reject(error);
                            }
                        } else {
                            return resolve(response);
                        }
                    }
                    return resolve(response);
                }).catch(error => {
                    // Fixes a CORS issue in Chrome and
                    // defaults to bad gateway when
                    // no response is provided
                    if (!error.response) {
                        const {message} = error;
                        if (message && message.toLowerCase().indexOf('network error') >= 0) {
                            error.response = {
                                status: 502
                            };
                        }
                    }
                    reject(error);
                });
        });
    }

    static put(url: string, requestData?: unknown, headers?: unknown): Promise<AxiosResponse> {
        return new Promise ((resolve, reject) => {
            const axiosHeaders = headers ? {
                headers
            } : undefined;
            const axiosConfig: AxiosRequestConfig | undefined = 
                (axiosHeaders) ?
                    Object.assign({}, axiosHeaders) : undefined;

            axios
                .put(url, requestData, axiosConfig)
                .then(response => {
                    const { data } = response;
                    if ((typeof data) === 'string') {
                        try {
                            response.data = JSON.parse(data);
                        } catch(e) { return; }
                    }
                    resolve(response);
                }).catch(error => {
                    // Fixes a CORS issue in Chrome and
                    // defaults to bad gateway when
                    // no response is provided
                    if (!error.response) {
                        const {message} = error;
                        if (message &&
                            message.toLowerCase().indexOf('network error') >= 0) {
                            error.response = {
                                status: 502
                            };
                        }
                    }
                    reject(error);
                });
        });
    }

    static post(url: string, requestData?: unknown, headers?: unknown): Promise<AxiosResponse> {
        return new Promise ((resolve, reject) => {
            const axiosHeaders = headers ? {
                headers
            } : undefined;
            const axiosConfig: AxiosRequestConfig | undefined = 
                axiosHeaders ?
                    Object.assign({}, axiosHeaders) : undefined;
            
            axios
                .post(url, requestData, axiosConfig)
                .then(response => {
                    const { data } = response;
                    if ((typeof data) === 'string') {
                        try {
                            response.data = JSON.parse(data);
                        } catch(e) { return; }
                    }
                    resolve(response);
                }).catch(error => {
                    // Fixes a CORS issue in Chrome and
                    // defaults to bad gateway when
                    // no response is provided
                    if (!error.response) {
                        const {message} = error;
                        if (message &&
                            message.toLowerCase().indexOf('network error') >= 0) {
                            error.response = {
                                status: 502
                            };
                        }
                    }
                    reject(error);
                });
        });
    }

    static patch(url: string, requestData?: unknown, headers?: unknown): Promise<AxiosResponse> {
        return new Promise ((resolve, reject) => {
            const axiosHeaders = headers ? {
                headers
            } : undefined;
            const axiosConfig: AxiosRequestConfig | undefined = 
                axiosHeaders ?
                    Object.assign({}, axiosHeaders) : undefined;
            
            axios
                .patch(url, requestData, axiosConfig)
                .then(response => {
                    const { data } = response;
                    if ((typeof data) === 'string') {
                        try {
                            response.data = JSON.parse(data);
                        } catch(e) { return; }
                    }
                    resolve(response);
                }).catch(error => {
                    // Fixes a CORS issue in Chrome and
                    // defaults to bad gateway when
                    // no response is provided
                    if (!error.response) {
                        const {message} = error;
                        if (message &&
                            message.toLowerCase().indexOf('network error') >= 0) {
                            error.response = {
                                status: 502
                            };
                        }
                    }
                    reject(error);
                });
        });
    }

    static delete(url: string, params?: unknown, headers?: unknown, isBodyPayload?: boolean): Promise<AxiosResponse> {
        return new Promise ((resolve, reject) => {
            // If BodyPayload is enabled
            // request will send the params as data field instead
            const axiosParams = params ?
                isBodyPayload ? { data: params } :
                    { params } : undefined;
            const axiosHeaders = headers ? {
                headers
            } : undefined;
            const axiosConfig: AxiosRequestConfig | undefined = 
                (axiosParams || axiosHeaders) ?
                    Object.assign({}, axiosParams, axiosHeaders) : undefined;

            axios
                .delete(url, axiosConfig)
                .then(response => {
                    const { data } = response;
                    if ((typeof data) === 'string') {
                        try {
                            response.data = JSON.parse(data);
                        } catch(e) { return; }
                    }
                    resolve(response);
                }).catch(error => {
                    // Fixes a CORS issue in Chrome and
                    // defaults to bad gateway when
                    // no response is provided
                    if (!error.response) {
                        const {message} = error;
                        if (message &&
                             message.toLowerCase().indexOf('network error') >= 0) {
                            error.response = {
                                status: 502
                            };
                        }
                    }
                    reject(error);
                });
        });
    }
}
